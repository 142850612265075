import crowntv from '../assets/image/crowntv.png'
import opensource from '../assets/image/opensource.jpg'


export const achievementData = {
    bio : "  A dynamic and dedicated Java Developer with 2 year of hands-on software development experience,specialized in Java programming. Proficient in coding, troubleshooting, and optimizing Java applications. Adept at collaborating with cross-functional teams to deliver high-quality software solutions. Ability to swiftly acquire new technologies and a strong commitment to ongoing learning and growth are required.",
    achievements : [
        {
            id : 1,
            title : 'Crown TV',
            details : 'Worked with Crown TV Africa as a technical writer.',
            date : 'June, 20, 2023',
            field : 'Automation',
            image : crowntv
        },
        {
            id : 2,
            title : 'Google Developer Student Club(UNILAG BRANCH)',
            details : 'Developed with scores of developers across faculties, different applications that 150+ students used on their daily lives on campus including e-voting, e-learning applications.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'
        },
        {
            id : 3,
            title : 'Open Source',
            details : 'Contributed to more various open sourced projects accross various platforms including Tulibian, Shoppizer, Unilag Hub, GDSC to mention a few.',
            date : 'Aug 20, 2021',
            field : 'Automation',
            image : opensource
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/