export const socialsData = {
     github: 'https://github.com/Kordedekehine',
    // facebook: '',
    linkedIn: 'https://www.linkedin.com/in/korede-salami-182137293/',
    //instagram: 'https://www.instagram.com/polls.art',
    // codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/akehindekorede',
    // reddit: 'https://www.reddit.com/user/',
     blogger: 'https://hashnode.com/@AkoredeTheOne',
    //medium: 'https://medium.com/@pollsart',
     stackOverflow: 'https://stackoverflow.com/users/17602404/salami-korede',
    // gitlab: 'https://gitlab.com/',
   // youtube: 'https://www.youtube.com/@POLLSART'
}