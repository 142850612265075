/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Tom Collins',
        title: 'Developer',
        text: "I worked with Korede once, and that's my best experience.",
        image: boy2
    },
    {
        id: 2,
        name: 'Wunmi Gray',
        title: 'Client',
        text: " Korede attention to detail is top notch.",
        image: girl2
    },
    {
        id: 3,
        name: 'Amina Muhammad',
        title: 'Frontend Developer',
        text: "Really solid guy, always ready to help.",
        image: girl1
    },
    {
        id: 4,
        name: 'Nwoye Godswill',
        title: 'UI/UX',
        text: " Easy to work with guy.",
        image: boy1
    },
    {
        id: 5,
        name: 'Aunt Phil',
        title: 'Client',
        text: 'Love how Korede and his peers handle my works at 3folds.',
        image: girl3
    },
    {
        id: 6,
        name: 'Salia Ibraheem',
        title: 'Front-End Developer',
        text: "Korede has a rich understanding of User Interface...lol I'm not even gonna lie.",
        image: boy3
    }
]

