export const blogData = [
    {
        id: 1,
        title: 'Unraveling The Myth Behind Lambdas',
        description: 'Unraveling The Myth Behind Lambdas: Java 8 Lambda Expressions Total Breakdown',
        date: 'February 1, 2024',
        image: 'https://cdn.hashnode.com/res/hashnode/image/upload/v1706604063356/d4c46b2e-d94f-48ac-aa9b-65d8172a4d84.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp',
        url: 'https://salamikorede.hashnode.dev/unraveling-the-myth-behind-lambdas-java-8-lambda-expressions-total-breakdown'
    },
    {
        id: 2,
        title: 'WRITING A CLEAN CODE',
        description: '11 THINGS TO CONSIDER TO WRITE A CLEAN CODE',
        date: 'May ,6 2023',
        image: 'https://cdn.hashnode.com/res/hashnode/image/upload/v1676726492943/1181d74c-8602-46c2-8ec0-3ff86b42c80f.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp',
        url: 'https://salamikorede.hashnode.dev/how-to-write-a-clean-code-11-things-to-consider'
    },
    {
        id: 3,
        title: 'OBJECT ORIENTED PROGRAMMING DEMISTYFIED',
        description: 'An article that that totally demystified the concept of Object Oriented Programming',
        date: 'Nov ,17 2023',
        image: 'https://cdn.hashnode.com/res/hashnode/image/upload/v1696580256059/eca84e4a-cdfa-4c97-b3db-a0427f9a4480.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp',
        url: 'https://salamikorede.hashnode.dev/oop-demistyfied-oop-total-breakdown'
    },
    {
        id: 4,
        title: 'EFFECTIVE PRACTICE FOR WRITING CLEAN CODE',
        description: 'Uncle Bob clean code principles',
        date: 'June 1, 2023',
        image: 'https://cdn.hashnode.com/res/hashnode/image/upload/v1676052081100/8ab605b9-a8a6-4d15-8edc-76cd2bf3b983.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp',
        url: 'https://salamikorede.hashnode.dev/effective-practice-for-writing-clean-code'
    },
    {
        id: 5,
        title: 'UNDERSTANDING LOGS',
        description: 'Logging in Software Development.',
        date: 'July 10, 2023',
        image: 'https://cdn.hashnode.com/res/hashnode/image/upload/v1676724474189/8b6ca3a0-97ce-44f9-bde2-52cfb3ae485b.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp',
        url: 'https://salamikorede.hashnode.dev/the-need-for-logging-in-software-development'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/