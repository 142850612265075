import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
//  import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Virtual Star Manager Assistant',
        projectDesc: 'A platform specifically for managers to manage artiste bookings and schedules and also engage the fans.',
        tags: ['Paystack API', 'SpringBoot', 'Postgresq'],
        code: 'https://github.com/Kordedekehine/BookStar',
        demo: 'https://www.awesomescreenshot.com/video/24410386?key=aef0f9dd7fa3f63668441766865bf314',
        image: four
    },
    {
        id: 2,
        projectName: 'Chatting App',
        projectDesc: 'Real time chatting App',
        tags: ['Websocket','Kafka','SpringBoot'],
        code: 'https://github.com/Kordedekehine/Quik-chat',
        demo: 'https://www.awesomescreenshot.com/video/24227904?key=5fbde853a635a27e27992281871795b8',
        image: eight
    },
    {
        id: 3,
        projectName: 'Word University',
        projectDesc: 'These projects is a language teaching app in a form of cbt app ',
        tags: ['Google Transalation API', 'Open AI API', 'Pdf generator', 'Postgresql'],                
        code: 'https://github.com/Kordedekehine/word-university',
        demo: 'https://language-app-qnnm.onrender.com/swagger-ui/index.html#/',
        image: three
    },
    {
        id: 5,
        projectName: 'Bank App Simulations',
        projectDesc: 'A mere simulation of a bank application',
        tags: ['Cloudinary API', 'SpringBoot', 'MySQL'],
        code: 'https://github.com/Kordedekehine/SpringBoot-Bank-App',
        demo: 'https://github.com/Kordedekehine/SpringBoot-Bank-App',
        image: one
    },
    {
        id: 4,
        projectName: 'Student Social App',
        projectDesc: 'Social platform for students for enlightening themselves on topics.',
        tags: ['students unite app', 'SpringBoot App'],
        code: 'https://github.com/Kordedekehine/student-app',
        demo: 'https://github.com/Kordedekehine/student-app',
        image: two
    },
    
    {
        id: 6,
        projectName: 'Online Diary',
        projectDesc: 'Online Diary inspired by a movie My Liberation Note',
        tags: ['SpringBoot'],
        code: 'https://github.com/Kordedekehine/MY-LIBERATION-NOTES',
        demo: 'https://github.com/Kordedekehine/MY-LIBERATION-NOTES',
        image: six
    },
     {
         id: 7,
         projectName: 'MargaritaFarm Website',
         projectDesc: 'A Farm Website',
         tags: ['React', 'Redux', 'Bootstrap'],
         code: 'https://magaritasfarm.com.ng/',
         demo: 'https://magaritasfarm.com.ng/',
         image: two
     },
     {
        id: 8,
        projectName: 'Natafad Website',
        projectDesc: 'A Farm Website',
        tags: ['React', 'Redux', 'Bootstrap'],
        code: 'https://www.natafadintegratedservices.com/',
        demo: 'https://www.natafadintegratedservices.com/',
        image: two
    },
     {
        id: 9,
        projectName: 'En Masse',
        projectDesc: 'An agricultural idea startups to revolutionalize farming in Africa',
        tags: ['SpringBoot'],
        code: 'https://drive.google.com/drive/folders/1gkE-AYNTV9BhlIClWn5z5eBpDvs2qaet',
        demo: 'https://drive.google.com/drive/folders/1gkE-AYNTV9BhlIClWn5z5eBpDvs2qaet',
        image: five
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/