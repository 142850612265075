export const experienceData = [
    {
        id: 1,
        company: 'Auto X',
        jobtitle: 'Software Engineer Intern',
        startYear: '2022',
        endYear: '2022'
    },
    {
        id: 2,
        company: 'Google Student Developer Club(UNILAG)',
        jobtitle: 'Java Software Engineer',
        startYear: '2021',
        endYear: '2023'
    },
    {
        id: 3,
        company: '3folds',
        jobtitle: 'Software Developer',
        startYear: '2023',
        endYear: 'present'
    },
    {
        id: 4,
        company: 'Seven-Up Bottling Company(SBC)',
        jobtitle: 'System Support Engineer',
        startYear: '2024',
        endYear: 'present'
    },
]