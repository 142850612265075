export const educationData = [
    {
        id: 1,
        institution: 'Lennon Jubilee High School',
        course: 'Higher Secondary Education',
        startYear: '2010',
        endYear: '2016'
    },
    {
        id: 2,
        institution: 'SYSTEM BUSINESS SCHOOL(SBS)',
        course: 'Software Design',
        startYear: '2020',
        endYear: '2021'
    },                           
    {
        id: 3,
        institution: 'University Of Lagos',
        course: 'Philosophy',
        startYear: '2017',
        endYear: '2021'
    },
]