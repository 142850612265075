import resume from '../assets/pdf/resume.pdf'
import image from '../assets/image/image.jpg'

export const headerData = {
    name: 'Salami Korede',
    title: "SOFTWARE DEVELOPER.",
    desciption:" Eager to contribute my technical expertise and problem-solving skills to challenging projects in the IT industry. ",
    image: image,
    resumePdf: resume
}
//'https://raw.githubusercontent.com/PhantomScript/asset-container/main/developer-portfolio/landingImg.png'